import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import TableComponent from "../../../components/TableComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ModalCadastroAbrir from "../../../components/ModalCadastroComplementar/Abrir";
import ModalCadastroMigrar from "../../../components/ModalCadastroComplementar/Migrar";
import ModalDefinirPlano from "../../../components/ModalDefinirPlano";
import ModalStatus from "../../../components/ModalStatus";
import Button from "../../../components/ButtonComponent";
import ModalNovaEmpresa from "../components/ModalNovaEmpresa";

import validateFields from "../../../utils/validateFields";

import cliente from "../../../config/cliente";

import empresasService from "../../../services/empresasService";

import classes from "./styles.module.scss";
import DatePicker from "../../../components/DatePicker";
import { Grid } from "@material-ui/core";
import exportData from "../../../services/exportData";
import Select from "../../../components/SelectComponent";
import { ModalFolha } from "../../../components/ModalFolha";

function EmpresasLista() {
  const history = useHistory();

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [loading, setLoading] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [confirmRemoveDialogBlock, setConfirmRemoveDialogBlock] =
    useState(false);
  const [confirmRemoveDialogUnblock, setConfirmRemoveDialogUnblock] =
    useState(false);
  const [confirmRemoveDialogInactivate, setConfirmRemoveDialogInactivate] =
    useState(false);
  const [confirmRemoveDialogActivate, setConfirmRemoveDialogActivate] =
    useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [blockId, setBlockId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [openModalFolha, setOpenModalFolha] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrors, setCallbackErrorList] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [duration, setCallbackDuration] = useState(6000);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");

  const is36z = cliente?.cliente && cliente.cliente === "36z";

  const isCBHub = cliente?.cliente === "contabilhub";

  const perfilAcesso = window.localStorage.getItem("perfil_acesso");

  const isEmpresaPlataforma =
    perfilAcesso && (perfilAcesso === "1" || perfilAcesso === "2");

  const [modalCadastroAbrir, setModalCadastroAbrir] = useState(false);
  const [modalCadastroMigrar, setModalCadastroMigrar] = useState(false);
  const [modalCadastroData, setModalCadastroData] = useState({});

  const [dataModalDefinirPlano, setDataModalDefinirPlano] = useState({});
  const [showModalDefinirPlano, setShowModalDefinirPlano] = useState(false);

  const [showModalNovaEmpresa, setShowModalNovaEmpresa] = useState(false);

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [statusSelecionado, setStatusSelecionado] = useState("");

  const [listSituacaoEmpresa, setListSituacaoEmpresa] = useState([]);
  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_empresa" },
      { columnName: "CNPJ/CPF", dataRef: "cnpj" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Estado", dataRef: "uf" },
      { columnName: "Cidade", dataRef: "cidade" },
      { columnName: "Status", dataRef: "status_empresa" },
    ],
    options: {
      edit: true,
      delete: true,
      searchFile: true,
      more: is36z
        ? [{ icon: "signature", label: "Faturas", action: "assinatura" }]
        : isEmpresaPlataforma
        ? !isCBHub
          ? [
              { icon: "edit", label: "Status e observações", action: "status" },
              {
                icon: "edit",
                label: "Editar Empresa",
                action: "editar-empresa",
              },
              {
                icon: "clipboard",
                label: "Cadastro complementar",
                action: "cadastro-complementar",
              },
              {
                icon: "edit-user",
                label: "Perfil Tributário",
                action: "editar-perfil",
              },
              {
                icon: "certificate",
                label: "Certificado",
                action: "certificado",
              },
              // { icon: "settings", label: "Configurações", action: "configuracoes" },
              { icon: "dollar", label: "Definir Plano", action: "plano" },
              { icon: "signature", label: "Faturas", action: "assinatura" },
              {
                icon: "info-large",
                label: "Configuração do Boleto",
                action: "configuracao-iugu",
              },
            ]
          : [
              { icon: "edit", label: "Status e observações", action: "status" },
              // { icon: "edit", label: "Editar Empresa", action: "editar-empresa" },
              {
                icon: "clipboard",
                label: "Cadastro complementar",
                action: "cadastro-complementar",
              },
              {
                icon: "edit-user",
                label: "Perfil Tributário",
                action: "editar-perfil",
              },
              {
                icon: "certificate",
                label: "Certificado Digital",
                action: "certificado",
              },
              // { icon: "settings", label: "Configurações", action: "configuracoes" },
              { icon: "dollar", label: "Definir Plano", action: "plano" },
              { icon: "signature", label: "Faturas", action: "assinatura" },
              {
                icon: "info-large",
                label: "Configuração do Boleto",
                action: "configuracao-iugu",
              },
            ]
        : !isCBHub
        ? [
            { icon: "edit", label: "Editar Empresa", action: "editar-empresa" },
            {
              icon: "clipboard",
              label: "Cadastro complementar",
              action: "cadastro-complementar",
            },
            {
              icon: "edit-user",
              label: "Perfil Tributário",
              action: "editar-perfil",
            },
            {
              icon: "certificate",
              label: "Certificado",
              action: "certificado",
            },
            // { icon: "settings", label: "Configurações", action: "configuracoes" },
            { icon: "signature", label: "Faturas", action: "assinatura" },
          ]
        : [
            // { icon: "edit", label: "Editar Empresa", action: "editar-empresa" },
            {
              icon: "clipboard",
              label: "Cadastro complementar",
              action: "cadastro-complementar",
            },
            {
              icon: "edit-user",
              label: "Perfil Tributário",
              action: "editar-perfil",
            },
            {
              icon: "certificate",
              label: "Certificado",
              action: "certificado",
            },
            // { icon: "settings", label: "Configurações", action: "configuracoes" },
            { icon: "signature", label: "Faturas", action: "assinatura" },
          ],
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      export: false,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    statusEmpresa: null,
    dataInicio: null,
    dataFim: null,
  });

  const exportToXLS = {
    url: "exportar-empresas-xlsx",
    fileName: "empresas",
    fileType: "xlsx",
    params: null,
  };

  let updateTable = useCallback(
    async (currParams) => {
      window.scrollTo(0, 0);
      setLoading(true);
      const result = await empresasService.getEmpresas(
        currParams?.currentPage || defaultConfigTable.currentPage,
        currParams?.orderBy || defaultConfigTable.orderBy,
        currParams?.orderByType || defaultConfigTable.orderByType,
        currParams?.perPage || defaultConfigTable.perPage,
        currParams?.searchTerm || defaultConfigTable.searchTerm,
        currParams?.statusEmpresa || defaultConfigTable.statusEmpresa,
        currParams?.dataInicio || defaultConfigTable.dataInicio,
        currParams?.dataFim || defaultConfigTable.dataFim
      );

      if (result.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (result.data.hasOwnProperty("data")) {
        const resultData = {
          ...result.data,
          data: result.data?.data?.map((item) => ({
            ...item,
            cnpj: item?.cnpj
              ? item.cnpj
              : item?.socio_empresas?.find((socio) => socio?.cpf)?.cpf
              ? item?.socio_empresas?.find((socio) => socio?.cpf)?.cpf
              : "",
          })),
        };
        setListRequest(resultData);
      } else {
        const resultData = {
          ...result,
          data: result?.data?.map((item) => ({
            ...item,
            cnpj: item?.cnpj
              ? item.cnpj
              : item?.socio_empresas?.find((socio) => socio?.cpf)?.cpf
              ? item?.socio_empresas?.find((socio) => socio?.cpf)?.cpf
              : "",
          })),
        };
        setListRequest(resultData);
      }
      setLoading(false);
    },
    [
      // defaultConfigTable.currentPage,
      // defaultConfigTable.orderBy,
      // defaultConfigTable.orderByType,
      // defaultConfigTable.perPage,
      // defaultConfigTable.searchTerm,
      // defaultConfigTable.statusEmpresa,
    ]
  );

  useEffect(() => {
    updateTable();
  }, [updateTable, companyId]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest]);

  useEffect(() => {
    loadSituacaoEmpresa();
  }, []);

  const loadSituacaoEmpresa = useCallback(async () => {
    const response = await empresasService.selectSituacaoEmpresa();
    if (response.status === 200) {
      setListSituacaoEmpresa(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [empresasService.selectSituacaoEmpresa]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy =
      newOrderBy.orderBy === "status_empresa" ? "status" : newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable(newDefaultConfigTable);
  };

  let onEdit = (id) => {
    history.push(`/editar-empresa/${id}`, {
      activeStepNavigation: true,
      edit: true,
    });
  };

  const onLookCompany = (id) => {
    history.push(`/detalhes-empresa/${id}`, {
      activeStepNavigation: true,
      details: true,
    });
  };

  async function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  let handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
    setConfirmRemoveDialogBlock(false);
    setConfirmRemoveDialogUnblock(false);
    setConfirmRemoveDialogInactivate(false);
    setConfirmRemoveDialogActivate(false);
  };

  let onDelete = async () => {
    setLoading(true);
    const response = await empresasService.removerEmpresa(deleteId);
    if (response.status === 401) {
      setConfirmRemoveDialog(false);
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status !== 200) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setConfirmRemoveDialog(false);
      if (response.data.error) {
        if (response.data.error.includes("SQLSTATE[23000]")) {
          setCallbackErrorList([
            "Você não pode deletar uma empresa que está sendo usada em outros registros.",
          ]);
        } else setCallbackErrorList(Object.values(response.data.error));
      } else setCallbackErrorList(["Ocorreu um erro"]);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Empresa excluida com sucesso!");
      setCallbackDuration(2000);
      setLoading(false);
      setConfirmRemoveDialog(false);
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
        updateTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
      handleRemoveCancel();
    }
  };

  let onBlockCompany = async () => {
    setLoading(true);

    let findCompany = listRequest?.data?.find(
      (item) => item.id_empresa === blockId
    );

    const response = await empresasService.bloqueioEmpresa(
      blockId,
      findCompany?.bloqueio === 0 ? 1 : 0
    );

    if (response.status === 401) {
      setLoading(false);
      setConfirmRemoveDialogBlock(false);
      setConfirmRemoveDialogUnblock(false);
      setOpenLoginExpirado(true);
      return;
    }
    if (response.status !== 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setConfirmRemoveDialogBlock(false);
      setConfirmRemoveDialogUnblock(false);
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    }
    if (response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        `${
          findCompany?.bloqueio === 0
            ? "Empresa bloqueada com sucesso!"
            : "Empresa desbloqueada com sucesso!"
        }`
      );
      setCallbackDuration(2000);
      setConfirmRemoveDialogBlock(false);
      setConfirmRemoveDialogUnblock(false);
      await updateTable();
      return;
    }

    handleRemoveCancel();
    setLoading(false);
  };

  let onInactivateCompany = async (date) => {
    setLoading(true);

    let findCompany = listRequest?.data?.find(
      (item) => item.id_empresa === blockId
    );
    let response;

    if (findCompany?.inativo === 0) {
      let fieldsToValidate = [
        {
          label: "datainativacao",
          value: date,
        },
      ];

      let fieldsErrors = validateFields(fieldsToValidate);

      if (fieldsErrors.length !== 0) {
        setConfirmRemoveDialogInactivate(false);
        setCallbackType("error");
        setCallbackDuration(3000);
        setCallbackMessage("Erro!");
        setCallbackErrorList(fieldsErrors);
        setCallbackShown(true);
        setLoading(false);
        return;
      }

      response = await empresasService.inativarEmpresa(
        blockId,
        1,
        date ? date : null
      );
    }

    if (findCompany?.inativo === 1) {
      response = await empresasService.ativarEmpresa(blockId, 0);
    }

    if (response.status === 401) {
      setLoading(false);
      setConfirmRemoveDialogInactivate(false);
      setConfirmRemoveDialogActivate(false);
      setOpenLoginExpirado(true);
      return;
    }
    if (response.status !== 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setConfirmRemoveDialogInactivate(false);
      setConfirmRemoveDialogActivate(false);
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    }
    if (response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        `${
          findCompany?.inativo === 0
            ? "Empresa inativada com sucesso!"
            : "Empresa ativada com sucesso!"
        }`
      );
      setCallbackDuration(2000);
      setConfirmRemoveDialogInactivate(false);
      setConfirmRemoveDialogActivate(false);
      await updateTable();
      return;
    }

    handleRemoveCancel();
    setLoading(false);
  };

  let onMoreAction = async (action) => {
    switch (action.action) {
      case "status": {
        setLoading(true);

        setCompanyId(action.id);
        setShowModalStatus(true);

        setLoading(false);
        break;
      }
      case "configuracao-iugu": {
        history.push(`/configuracoes-boleto/${action.id}`);
        break;
      }
      case "configuracoes": {
        history.push(`/configuracoes-empresas/${action.id}`);
        break;
      }
      case "editar-perfil": {
        history.push(`/perfil-tributario/${action.id}`);
        break;
      }
      case "editar-empresa": {
        history.push(`/editar-empresa/${action.id}`);
        break;
      }
      case "certificado": {
        history.push(`/certificado/${action.id}`);
        break;
      }
      case "plano": {
        setLoading(true);

        setCompanyId(action.id);
        setShowModalDefinirPlano(true);

        setLoading(false);
        break;
      }
      case "assinatura": {
        history.push(`/faturas/${action.id}`);
        break;
      }
      case "cadastro-complementar": {
        setLoading(true);
        const response = await empresasService.consultaComplemento(action.id);

        if (response.status === 200) {
          setCompanyId(action.id);
          const { data } = response;
          const tipoCadastro = data.tipo_cadastro;

          if (tipoCadastro === 1) {
            setModalCadastroAbrir(true);
            setModalCadastroData(response.data);
          }

          if (tipoCadastro === 2) {
            setModalCadastroMigrar(true);
            setModalCadastroData(response.data);
          }

          if (tipoCadastro !== 1 && tipoCadastro !== 2) {
            setCallbackType("error");
            setCallbackMessage("Erro!");
            setCallbackErrorList([
              "Não foi possível exibir os dados de cadastro complementar.",
            ]);
            setCallbackDuration(3000);
            setCallbackShown(true);
          }
        }

        setLoading(false);
        break;
      }
      case "bloquear-empresa": {
        setLoading(true);
        setBlockId(action.id);
        setConfirmRemoveDialogBlock(true);
        setLoading(false);
        break;
      }
      case "desbloquear-empresa": {
        setLoading(true);
        setBlockId(action.id);
        setConfirmRemoveDialogUnblock(true);
        setLoading(false);
        break;
      }
      case "inativar-empresa": {
        setLoading(true);
        setBlockId(action.id);
        setConfirmRemoveDialogInactivate(true);
        setLoading(false);
        break;
      }
      case "ativar-empresa": {
        setLoading(true);
        setBlockId(action.id);
        setConfirmRemoveDialogActivate(true);
        setLoading(false);
        break;
      }
      case "dados-folha": {
        setLoading(true);
        setCompanyId(action.id);
        setOpenModalFolha(true);
        setLoading(false);
        break;
      }

      default:
        break;
    }
  };

  const cbClickMore = useCallback(
    (id) => {
      let findCompany = listRequest?.data?.find(
        (item) => item.id_empresa === id
      );

      let newDefaultConfigTable = defaultConfigTable;

      if (isEmpresaPlataforma) {
        if (
          !newDefaultConfigTable.options.more.find(
            (item) => item.action === "bloquear-empresa"
          )
        ) {
          let newMoreOptions = newDefaultConfigTable.options.more.filter(
            (option) => option.action != "desbloquear-empresa"
          );

          newDefaultConfigTable.options.more = [
            ...newMoreOptions,
            {
              icon: "lock",
              label: "Bloquear empresa",
              action: "bloquear-empresa",
            },
          ];
        }

        if (
          !newDefaultConfigTable.options.more.find(
            (item) => item.action === "desbloquear-empresa"
          ) &&
          findCompany?.bloqueio === 1
        ) {
          let newMoreOptions = newDefaultConfigTable.options.more.filter(
            (option) => option.action != "bloquear-empresa"
          );

          newDefaultConfigTable.options.more = [
            ...newMoreOptions,
            {
              icon: "lock",
              label: "Desbloquear empresa",
              action: "desbloquear-empresa",
            },
          ];
        }

        if (
          !newDefaultConfigTable.options.more.find(
            (item) => item.action === "inativar-empresa"
          )
        ) {
          let newMoreOptions = newDefaultConfigTable.options.more.filter(
            (option) => option.action != "ativar-empresa"
          );

          newDefaultConfigTable.options.more = [
            ...newMoreOptions,
            {
              icon: "eye-slash",
              label: "Inativar empresa",
              action: "inativar-empresa",
            },
          ];
        }

        if (
          !newDefaultConfigTable.options.more.find(
            (item) => item.action === "ativar-empresa"
          ) &&
          findCompany?.inativo === 1
        ) {
          let newMoreOptions = newDefaultConfigTable.options.more.filter(
            (option) => option.action != "inativar-empresa"
          );

          newDefaultConfigTable.options.more = [
            ...newMoreOptions,
            {
              icon: "eye-slash",
              label: "Ativar empresa",
              action: "ativar-empresa",
            },
          ];
        }
      }
      let newMoreconfig = newDefaultConfigTable.options.more.filter(
        (option) => option.action !== "dados-folha"
      );
      newMoreconfig = [
        ...newMoreconfig,
        {
          icon: "folha",
          label: "Dados da folha",
          action: "dados-folha",
        },
      ];
      newDefaultConfigTable.options.more = newMoreconfig;
      setDefaultConfigTable(newDefaultConfigTable);
      // updateTable(newDefaultConfigTable);
    },
    [listRequest, defaultConfigTable]
  );

  const handleCloseModalStatus = () => {
    return setShowModalStatus(false);
  };

  const handleCloseModalPlano = () => {
    setCompanyId(null);
    return setShowModalDefinirPlano(false);
  };

  const handleCloseModal = (type) => {
    if (type === "abrir") setModalCadastroAbrir(false);

    if (type === "migrar") setModalCadastroMigrar(false);

    setModalCadastroData({});
  };

  const handleNovaEmpresa = useCallback(() => {
    return history.push("/empresas-cadastro");
  }, []);

  const handleNovoPreCadastro = useCallback(() => {
    return history.push("/pre-cadastro");
  }, []);

  const handleSelectStatus = async (id) => {
    setStatusSelecionado(id);
  };

  const handleCloseModalFolha = () => {
    setCompanyId(null);
    return setOpenModalFolha(false);
  };

  const handleCleanFilters = async () => {
    setStatusSelecionado(null);
    setDataInicio("");
    setDataFim("");
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      statusEmpresa: null,
      dataInicio: "",
      dataFim: "",
    });
    updateTable({
      ...defaultConfigTable,
      currentPage: 1,
      statusEmpresa: null,
      dataInicio: "",
      dataFim: "",
    });
  };

  const handleApplyFilters = () => {
    if (statusSelecionado === "" || statusSelecionado === null) {
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Selecione um status."]);
      setCallbackDuration(3000);
      setCallbackShown(true);
      return;
    }

    const newParams = { ...defaultConfigTable };
    newParams.statusEmpresa = statusSelecionado;
    newParams.dataInicio = dataInicio;
    newParams.dataFim = dataFim;
    newParams.currentPage = 1;
    console.log("clicado");
    setDefaultConfigTable(newParams);
    updateTable(newParams);
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Status"
              required
              initialValue={
                statusSelecionado !== undefined
                  ? listSituacaoEmpresa.find(
                      (item) => item.id === statusSelecionado
                    )?.nome
                  : ""
              }
              title="Selecione um status"
              list={listSituacaoEmpresa}
              callback={handleSelectStatus}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="De"
              initDate={dataInicio}
              handleChange={(value) => setDataInicio(value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Até"
              initDate={dataFim}
              handleChange={(value) => setDataFim(value)}
            />
          </Grid>
          <Grid className={classes.marginLeftAuto}>
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  const onTableMoreAction = useCallback(
    async (dataFile) => {
      let aux = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          ...exportToXLS.params,
          order_by: defaultConfigTable.orderBy,
          order_by_type: defaultConfigTable.orderByType,
          status: defaultConfigTable.statusEmpresa,
          data_inicio: defaultConfigTable.dataInicio,
          data_fim: defaultConfigTable.dataFim,
        },
      };
      const response = await exportData(aux);
    },
    [
      defaultConfigTable.statusEmpresa,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
    ]
  );

  return (
    <div className="container-tabela-responsiva">
      {loading && <OverlayLoading />}
      {showModalNovaEmpresa && (
        <ModalNovaEmpresa
          open={showModalNovaEmpresa}
          onClose={() => setShowModalNovaEmpresa(false)}
        />
      )}
      <div className={classes.headerButton}>
        <Button
          onClick={handleNovaEmpresa}
          className="btn-primary"
          text="+ Nova Empresa"
        />
      </div>
      {!is36z && (
        <div className={classes.headerButton}>
          <div className={classes.containerHeaderButton}>
            {isCBHub && isEmpresaPlataforma && (
              <Button
                onClick={handleNovoPreCadastro}
                className={classes.btnPreCadastro + " btn-secondary"}
                text="pré-cadastro"
              />
            )}
            <Button
              onClick={handleNovaEmpresa}
              className="btn-primary"
              text="+ Nova Empresa"
            />
          </div>
        </div>
      )}
      <TableComponent
        idName="id_empresa"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        cbMoreAction={onMoreAction}
        cbTableMoreAction={onTableMoreAction}
        cbLookFile={onLookCompany}
        isCbCompany
        cbClickMore={cbClickMore}
        // exportXLS={exportToXLS}
        filterComponent={filterComponent()}
      />
      <ConfirmDialog
        title="BLOQUEAR EMPRESA"
        description="Você tem certeza que deseja bloquear a empresa?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        open={confirmRemoveDialogBlock}
        onCancel={handleRemoveCancel}
        onAccept={onBlockCompany}
      />
      <ConfirmDialog
        title="DESBLOQUEAR EMPRESA"
        description="Você tem certeza que deseja desbloquear a empresa?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        open={confirmRemoveDialogUnblock}
        onCancel={handleRemoveCancel}
        onAccept={onBlockCompany}
      />
      <ConfirmDialog
        title="INATIVAR EMPRESA"
        description="Você tem certeza que deseja inativar a empresa?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        open={confirmRemoveDialogInactivate}
        onCancel={handleRemoveCancel}
        onAccept={onInactivateCompany}
        dateLabel="Data de Inativação *"
        haveDate
        warning={
          <span className={classes.avisoInativar}>
            A ação de inativar a empresa resultará na exclusão do DDA se estiver
            ativo durante a busca.
          </span>
        }
      />
      <ConfirmDialog
        title="ATIVAR EMPRESA"
        description="Você tem certeza que deseja ativar a empresa?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        open={confirmRemoveDialogActivate}
        onCancel={handleRemoveCancel}
        onAccept={onInactivateCompany}
      />
      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        message={callbackMessage}
        errorList={callbackErrors}
        handleClose={handleClose}
        duration={duration}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <ModalLoginExpirado open={openLoginExpirado} />

      <ModalStatus
        idEmpresa={companyId}
        open={showModalStatus}
        onClose={handleCloseModalStatus}
        cbTable={updateTable}
      />

      <ModalDefinirPlano
        idEmpresa={companyId}
        open={showModalDefinirPlano}
        data={dataModalDefinirPlano}
        onClose={handleCloseModalPlano}
        cbTable={updateTable}
      />

      {modalCadastroAbrir && (
        <ModalCadastroAbrir
          companyId={companyId}
          open={modalCadastroAbrir}
          data={modalCadastroData}
          onClose={() => handleCloseModal("abrir")}
        />
      )}
      {modalCadastroMigrar && (
        <ModalCadastroMigrar
          companyId={companyId}
          open={modalCadastroMigrar}
          data={modalCadastroData}
          onClose={() => handleCloseModal("migrar")}
        />
      )}

      <ModalFolha
        idEmpresa={companyId}
        open={openModalFolha}
        onClose={handleCloseModalFolha}
        cbTable={updateTable}
      />
    </div>
  );
}

export default EmpresasLista;
