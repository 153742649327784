import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";

import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox, Grid } from "@material-ui/core";

import Input from "../../components/Input";
import Button from "../../components/ButtonComponent";
import Icon from "../../components/Icon";
import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";

import Boleto from "./Boleto2";

import Logo from "../../assets/img/logo-2.svg";
import creditCardIcon from "../../assets/img/credit-card-icon.svg";
import cardFlags from "../../assets/img/creditcard.png";
import scissors from "../../assets/img/scissors.svg";

import faturasService from "../../services/faturasService";
import boletoService from "../../services/boletoService";

import { formatDate } from "../../utils/dates";
import { numberToCurrency } from "../../utils/functions";
import { boletoMask, cpfCnpjMask, cepMask } from "../../utils/strings";
import validateFields from "../../utils/validateFields";

import classes from "./styles.module.scss";
import smallScreen from "../../utils/smallScreen";
import { jsPDF } from "jspdf";

function FaturaBoleto() {
  const CustomCheckbox = withStyles({
    root: {
      color: "#E8EBF1",
      marginTop: 10,
      width: 0,
      borderRadius: 4,
      "&$checked": {
        color: "#812990"
      }
    },
    checked: {}
  })(props => <Checkbox color="default" {...props} />);

  const history = useHistory();
  const params = useParams();
  const { idFatura, idBoleto } = params;
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const { consultaFatura, pagamentoBrasPag } = faturasService;
  const { consultaBoleto, consultaConfiguracoes } = boletoService;
  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackType, setCallbackType] = useState("");

  const [moreOptionsDisplay, setMoreOptionsDisplay] = useState(false);

  const [empresa, setEmpresa] = useState("");
  const [cnpjCpf, setCnpjCpf] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [estado, setEstado] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [numeroDocumento, setNumeroDocumento] = useState("");
  const [dataEmissao, setDataEmissao] = useState("");
  const [dataVencimento, setDataVencimento] = useState("");
  const [statusDocumento, setStatusDocumento] = useState("");
  const [itemFatura, setItemFatura] = useState([]);
  const [valorTotal, setValorTotal] = useState("");
  const [boletoConfig, setBoletoConfig] = useState({});

  const [checkedDebit, setCheckedDebit] = useState(false);
  const [checkedCredit, setCheckedCredit] = useState(false);

  const [cardNumber, setCardNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardValidity, setCardValidity] = useState("");
  const [cardSecurityCode, setCardSecurityCode] = useState("");

  const [digitableLine, setDigitableLine] = useState("");
  const isSmallScreen = smallScreen();

  const [boleto, setBoleto] = useState({
    linhaDigitavel: "",
    dataVencimento: "",
    beneficiario: "",
    agenciaCodigo: "",
    dataDocumento: "",
    numeroDocumento: "",
    especieDoc: "",
    aceite: "",
    dataProcessamento: "",
    nossoNumero: "",
    usoDoBanco: "",
    carteira: "",
    especie: "",
    quantidade: "",
    valor: "",
    valorDocumento: "",
    instrucao: "",
    desconto: "",
    juros: "",
    valorCobrado: "",
    nomePagador: "",
    cpfCnpjPagador: "",
    enderecoPagador: "",
    codBarras: "",
    contaCodigo: ''
  });

  const loadConfigBoleto = useCallback(async (idCliente) => {
    await consultaConfiguracoes(idCliente).then((response) => {
      if (response.status === 401) {
        return setOpenLoginExpirado(true);
      }

      return setBoletoConfig(response.data[0]);
    });
  }, [consultaConfiguracoes]);

  const loadFatura = useCallback(async () => {
    const response = await consultaFatura(idFatura);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      const {
        fatura_id,
        id_cliente,
        cliente_bairro,
        cliente_cidade,
        cliente_cnpj_cpf,
        cliente_endereco,
        cliente_inscricao_estadual,
        cliente_nome,
        cliente_uf,
        numero_documento,
        data_emissao,
        data_vencimento,
        status_documento,
        item_fatura,
        valor_total
      } = response.data;

      const newItemFatura = item_fatura.map(item => {
        return {
          ...item,
          item_valor: numberToCurrency(item.item_valor)
        };
      });

      setEmpresa(cliente_nome);
      setCnpjCpf(cpfCnpjMask(cliente_cnpj_cpf));
      setInscricaoEstadual(cliente_inscricao_estadual);
      setEstado(cliente_uf);
      setEndereco(cliente_endereco);
      setBairro(cliente_bairro);
      setCidade(cliente_cidade);
      setNumeroDocumento(fatura_id);
      setDataEmissao(formatDate(data_emissao));
      setDataVencimento(formatDate(data_vencimento));
      setStatusDocumento(status_documento);
      setItemFatura(newItemFatura);
      setValorTotal(numberToCurrency(valor_total));

      await loadConfigBoleto(id_cliente);
    }
  }, [consultaFatura, idFatura]);

  const loadBoleto = useCallback(async () => {
    const response = await consultaBoleto(idBoleto);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data } = response;

    setDigitableLine(data.linha_digitavel);

    const data_criacao = data.data_criacao.substring(0, 10);

    //data criacao em data do documento e data do processamento

    setBoleto({
      linhaDigitavel: boletoMask(data.linha_digitavel),
      dataVencimento: formatDate(data.data_vencimento),
      beneficiario: data.Beneficiario,
      agenciaCodigo: `${data.AgenciaCodigo}/${data.ContaCodigo || ""
        }`,
      dataDocumento: formatDate(data_criacao),
      numeroDocumento: data.numero_titulo_beneficiario,
      especieDoc: data.Especie,
      aceite: data.codigo_aceite,
      dataProcessamento: formatDate(data_criacao),
      nossoNumero: data.numero_titulo_cliente,
      usoDoBanco: "",
      carteira: data.numero_carteira,
      especie: "R$",
      quantidade: data.Quantidade,
      valor: numberToCurrency(data.valor_original).substring(
        3,
        numberToCurrency(data.valor_original).length
      ),
      valorDocumento: numberToCurrency(data.valor_original).substring(
        3,
        numberToCurrency(data.valor_original).length
      ),
      instrucao: data.intrucoes,
      desconto: numberToCurrency(data.valor_desconto).substring(
        3,
        numberToCurrency(data.valor_desconto).length
      ),
      juros: numberToCurrency(data.valor_juros).substring(
        3,
        numberToCurrency(data.valor_juros).length
      ),
      valorCobrado: numberToCurrency(
        Number(data.valor_original) -
        Number(data.valor_desconto) +
        Number(data.valor_juros)
      ).substring(
        3,
        numberToCurrency(
          Number(data.valor_original) -
          Number(data.valor_desconto) +
          Number(data.valor_juros)
        ).length
      ),
      nomePagador: data.pagador_nome,
      cpfCnpjPagador: cpfCnpjMask(data.pagador_numero_registro),
      enderecoPagador: `${data.pagador_cidade} ${data.pagador_bairro && data.pagador_bairro !== '' ? "- " + data.pagador_bairro : ""}
      ${" "}${data.pagador_endereco && data.pagador_endereco !== "" ? "- " + data.pagador_endereco : ""}
      ${" "}${data.pagador_cep && cepMask(data.pagador_cep) !== false ? "- " + cepMask(data.pagador_cep) : ""}`,
      codBarras: data.codigo_barra_numerico,
      cpfCnpjBeneficiario: cpfCnpjMask(boletoConfig.beneficiario_cnpj),
      enderecoBeneficiario: `${boletoConfig.municipio || ""} ${boletoConfig.bairro ? "- " + boletoConfig.bairro : ""
        } ${boletoConfig.cep && cepMask(boletoConfig.cep) !== false ? "- " + cepMask(boletoConfig.cep) : ""}`,
      valorPago: numberToCurrency(data.valor_pago),
    });
  }, [boletoConfig, consultaBoleto, idBoleto]);


  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadFatura();
      setLoading(false);
    })();
  }, [loadFatura, loadConfigBoleto]);

  useEffect(() => {
    (async () => {
      if (params?.idBoleto) {
        setLoading(true);
        await loadBoleto();
        setLoading(false);
      }
    })();
  }, [loadBoleto, params?.idBoleto])

  const handleChange = event => {
    if (event.target.name === "debit") {
      setCheckedDebit(event.target.checked);
      if (event.target.checked === true) {
        setCheckedCredit(false);
      }
    }

    if (event.target.name === "credit") {
      setCheckedCredit(event.target.checked);
      if (event.target.checked === true) {
        setCheckedDebit(false);
      }
    }
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    let tipoCartao = checkedCredit
      ? "CreditCard"
      : checkedDebit
        ? "DebitCard"
        : "";

    let hasErrors = false;

    const fieldsToValidade = [
      {
        label: "numerodocartao",
        value: cardNumber
      },
      {
        label: "nomeimpressonocartao",
        value: cardName
      },
      {
        label: "validade",
        value: cardValidity
      },
      {
        label: "codigodeseguranca",
        value: cardSecurityCode
      },
      {
        label: "tipodocartao",
        value: tipoCartao
      }
    ];

    const fieldsErrors = validateFields(fieldsToValidade);

    if (fieldsErrors.length !== 0) {
      setLoading(false);
      hasErrors = true;
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
    }

    if (hasErrors) return;

    const response = await pagamentoBrasPag(
      idFatura,
      tipoCartao,
      cardNumber,
      cardValidity,
      cardSecurityCode,
      "http://www.braspag.com.br",
      cardName.toUpperCase()
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200 || response.status === 201) {
      setLoading(false);
      setCallbackType("success");
      setCallbackMessage("Pago!");
      setCallbackShown(true);
      setCallbackDuration(1200);
    } else if (response.status === 400) {
      setLoading(false);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      setCallbackShown(true);
    }
    setLoading(false);
  };

  const handleCopy = () => {
    const digitableLine = document.querySelector("#digitableLine");
    digitableLine.select();
    document.execCommand("copy");
  };

  function MoreOptions({
    options,
    lineId,
    callback,
    handleMouseDown,
    displayHeader = false
  }) {
    let onClick = e => {
      let data;
      if (displayHeader) {
        data = {
          action: e.target.dataset.action
        };
      } else
        data = {
          id: lineId,
          action: e.target.dataset.action
        };
      callback(data);
    };

    return (
      <>
        {displayHeader && (
          <div className={`modal-options-header ${classes.modalOptionsHeader}`}>
            <ul className="modal-options-list-header">
              {options.map((option, index) => (
                <React.Fragment key={index}>
                  <li
                    className="modal-options-list-item"
                    data-action={option.action}
                    onMouseDown={e => {
                      handleMouseDown();
                      onClick(e);
                    }}
                  >
                    <span className="modal-options-list-item-icon">
                      <Icon name={option.icon} color="#0a2346" />
                    </span>
                    <span className="modal-options-list-item-label">
                      {option.label}
                    </span>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}
        {!displayHeader && (
          <div className={`modal-options ${classes.modalOptions}`}>
            <ul className="modal-options-list">
              {options.map((option, index) => (
                <React.Fragment key={index}>
                  <li
                    className="modal-options-list-item"
                    data-action={option.action}
                    onMouseDown={e => {
                      handleMouseDown();
                      onClick(e);
                    }}
                  >
                    <span className="modal-options-list-item-icon">
                      <Icon name={option.icon} />
                    </span>
                    <span className="modal-options-list-item-label">
                      {option.label}
                    </span>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}
      </>
    );
  }

  const onMoreOptions = action => {
    if (action.action === "imprimir") {
      const contentToPrint = document.querySelector("#printFatura");
      contentToPrint?.classList?.add("print");

      window.focus();
      window.print();
      return;
    }

    if (action.action === "logout") {
      window.localStorage.clear();
      return history.replace("/");
    }
  };

  let headerOptions = [
    {
      action: "imprimir",
      label: "Imprimir",
      icon: "printer-blue"
    },
    {
      action: "logout",
      label: "Sair",
      icon: "logout"
    }
  ];

  const boletoRef = useRef();

  // const handleBoletoPdfDownload = () => {
  //   const doc = new jsPDF();

  //   const boletoPdf = document.querySelector("#boleto");
  //   doc.html(boletoPdf, {
  //     filename: "boleto.pdf",
  //     callback: function (doc) {
  //       doc.save();
  //     },
  //   });
  // };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.goBack();
    }
  }

  const handleCardNumberChange = useCallback(event => {
    setCardNumber(event.target.value);
  }, []);

  function MobileBoletoDescription() {
    return (
      <>
        <h1 className={classes.paymentTitle}>
          Se preferir pague via boleto bancário
        </h1>
        <div className={classes.boletoDescription}>
          Abaixo está o código de barras do boleto, mas se precisar pode salvar
          o boleto completo em PDF clicando no botão mais abaixo.
        </div>
      </>
    );
  }

  return (
    <div className={classes.container}>
      {loading && <OverlayLoading />}
      {!isSmallScreen && (
        <div
          className={classes.menuWrapper}
          onClick={() => setMoreOptionsDisplay(prev => !prev)}
        >
          <Icon name="hamburger" size={22} color="#EF4066" className="icon" />
          {moreOptionsDisplay && (
            <MoreOptions
              handleMouseDown={() => setMoreOptionsDisplay(false)}
              options={headerOptions}
              callback={onMoreOptions}
              displayHeader
            />
          )}
        </div>
      )}
      <div
        id="printFatura"
        className="session-container user-register-container"
      >
        <span className="session-container-header">
          <div className={classes.wrapper}>
            <section className={classes.panel}>
              <div className={classes.logoWrapper}>
                <img src={Logo} />
              </div>
              <div className={classes.infoWrapper}>
                <span>A HUB SOLUCOES EM GESTAO E TECNOLOGIA -</span>
                <span>CONTABILHUB LTDA</span>
                <span>CNPJ: 52.237.580/0001-39</span>
                <span>comercial@contabilhub.com.br</span>
              </div>
            </section>
            <Grid
                container
                spacing={3}
                justify="center"
              className={classes.header}
            >
              <Grid item xs={12} sm={7} md={7}>
                <h1>FATURA</h1>
              </Grid>
              <Grid item className={classes.headerInfo} xs={12} sm={5} md={5}>
                <div className={classes.headerInfoLabels}>
                  <span>Número do Documento:</span>
                  <span>Data de emissão:</span>
                  <span>Data de vencimento:</span>
                </div>
                <div className={classes.headerInfoValues}>
                  <span>{numeroDocumento}</span>
                  <span>{dataEmissao}</span>
                  <span>{dataVencimento}</span>
                </div>
              </Grid>
            </Grid>
            <section className={classes.invoiceDescription}>
              <div className={classes.companyInfo}>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyName}`}
                >
                  <span className={classes.infoTitle}>Empresa:</span>
                  <span className={classes.infoValue}>{empresa}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyAddress}`}
                >
                  <span className={classes.infoTitle}>Endereço:</span>
                  <span className={classes.infoValue}>{endereco}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyDistrict}`}
                >
                  <span className={classes.infoTitle}>Bairro:</span>
                  <span className={classes.infoValue}>{bairro}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyCpfCnpj}`}
                >
                  <span className={classes.infoTitle}>CNPJ/CPF:</span>
                  <span className={classes.infoValue}>{cnpjCpf}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyStateRegistration}`}
                >
                  <span className={classes.infoTitle}>
                    Inscriçao Estadual:
                  </span>
                  <span className={classes.infoValue}>{inscricaoEstadual}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyCity}`}
                >
                  <span className={classes.infoTitle}>Cidade:</span>
                  <span className={classes.infoValue}>{cidade}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyState}`}
                >
                  <span className={classes.infoTitle}>Estado:</span>
                  <span className={classes.infoValue}>{estado}</span>
                </div>
              </div>
              <div className={classes.serviceInfo}>
                <h1 className={classes.serviceInfoTitle}>
                  Descrição de Serviços
                </h1>
                <table className={classes.serviceInfoTable}>
                  <thead>
                    <tr className={classes.alignLeft}>
                      <th className={classes.alignLeft}>ID</th>
                      <th className={classes.alignLeft}>Descrição</th>
                      <th className={classes.alignRight}>Valor (R$)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemFatura.map(item => {
                      let list = [];
                      for (let i = 0; i < item.item_quantidade; i++) {
                        list.push(
                          <tr>
                            <td className={classes.alignLeft}>
                              {item.fatura_item_id}
                            </td>
                            <td className={classes.alignLeft}>{item.item}</td>
                            <td className={classes.alignRight}>
                              {item.item_valor}
                            </td>
                          </tr>
                        );
                      }
                      return list;
                    })}
                    <tr>
                      <td colSpan={2} className={classes.alignRight}>
                        TOTAL
                      </td>
                      <td className={classes.alignRight}>{valorTotal}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section className={classes.payment}>
              <h1 className={classes.paymentTitle}>
                Pague com cartão de <br /> Débito, Crédito ou Boleto
              </h1>
              <div className={classes.paymentInfo}>
                <Grid
                  container
                  className={classes.creditCardChoice}
                  justify="space-between"
                >
                  <Grid
                    item
                    container
                    xs={12}
                    sm={8}
                    md={8}
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item>
                      <div
                        className={`${classes.flexCenter} ${classes.debitCard}`}
                      >
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={checkedDebit}
                              onChange={handleChange}
                              name="debit"
                            />
                          }
                        />
                        <img width={30} src={creditCardIcon} alt="" />
                        <span className={classes.checkboxLabel}>Débito</span>
                      </div>
                    </Grid>
                    <Grid item>
                      <div
                        className={`${classes.flexCenter} ${classes.creditCard}`}
                      >
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={checkedCredit}
                              onChange={handleChange}
                              name="credit"
                            />
                          }
                        />
                        <img width={30} src={creditCardIcon} alt="" />
                        <span className={classes.checkboxLabel}>Crédito</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} sm={3} md={3} justify="center">
                    <Grid item>
                      <div className={classes.brandsWrapper}>
                        <img src={cardFlags} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <form className={classes.paymentForm}>
                  <div className={`${classes.flexColumn}`}>
                    <Input
                      label="Número do cartão"
                      required
                      tipo="creditCard"
                      handleInputChange={handleCardNumberChange}
                    />
                  </div>
                  <div className={`${classes.flexColumn}`}>
                    <Input
                      label="Nome impresso no cartão"
                      required
                      handleInputChange={event =>
                        setCardName(event.target.value)
                      }
                    />
                  </div>
                  <div className={`${classes.flexColumn}`}>
                    <Input
                      label="Validade"
                      tipo="creditCardValidity"
                      required
                      title="__/____"
                      handleInputChange={event =>
                        setCardValidity(event.target.value)
                      }
                    />
                  </div>
                  <div className={`${classes.flexColumn}`}>
                    <Input
                      label="Código de segurança"
                      tipo="cvv"
                      required
                      title="____"
                      handleInputChange={event =>
                        setCardSecurityCode(event.target.value)
                      }
                    />
                  </div>
                </form>
                <div className={classes.buttonArea}>
                  <Button
                    onClick={handleFormSubmit}
                    className="btn-success"
                    text="Pagar"
                  />
                </div>
              </div>
              <div className={classes.scissorsIcon}>
                <img src={scissors} />
              </div>
            </section>
            {isSmallScreen && <MobileBoletoDescription />}
            <section className={classes.bankSlip}>
              <div className={classes.bankSlipLine}>
                <h1>Copiar linha digitável</h1>
                <Grid container spacing={2} className={classes.lineActions}>
                  <Grid item xs={12} sm={12} md={8}>
                    <div className={classes.bankSlipInputContainer}>
                      <Input
                        id="digitableLine"
                        readOnly
                        value={digitableLine}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} container justify="center">
                    <Button
                      className="primary-outline"
                      icone="copy"
                      text="Copiar"
                      onClick={handleCopy}
                    />
                  </Grid>
                </Grid>
                <div style={{ height: isSmallScreen ? 50 : 0 }} />
              </div>
              <div
                id="boleto"
                className={classes.bankSlipContainer}
                style={{ display: isSmallScreen ? "none" : "inherit" }}
              >
                <Boleto boleto={boleto} ref={boletoRef} />
              </div>
            </section>
            <section className={classes.bankSlipActions}>
              {/* <Button
                className="primary-outline"
                icone="download-thin"
                iconeColor="#812990"
                text="Salvar em PDF"
                onClick={handleBoletoPdfDownload}
              /> */}

              <ReactToPrint
                bodyClass="print"
                trigger={() => (
                  <Button
                    className="primary-outline-small"
                    icone="printer"
                    iconeColor="#812990"
                    text="Imprimir"
                    style={{ fontWeight: 500 }}
                  />
                )}
                content={() => boletoRef.current}
              />
            </section>
          </div>
        </span>
      </div>
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
}

export default FaturaBoleto;
